.hotel-review-div{
  background-color: #3e3e42;
  padding-top: 5%;
  padding-bottom: 100%;
}

.no-reviews-div {
  background-color: lightcoral;
  height: auto;
  width: auto;
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 5%;
  text-align: center;
  padding: 10px;
}
.add-reviews-no-reviews-div {
  padding: 10px;
}

.no-reviews-h1 {
  font-size: 50px;
}

.button-comment-noreview {
  background-color: white;
  padding: 10px;
  font-size: 16px;
}

@media screen and (max-width: 850px) {
  .no-reviews-div {
    background-color: lightcoral;
    height: auto;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    text-align: center;
    padding: 10px;
  }
  .add-reviews-no-reviews-div {
    padding: 10px;
  }

  .no-reviews-h1 {
    font-size: 25px;
  }

  .button-comment-noreview {
    background-color: white;
    padding: 10px;
    font-size: 16px;
  }
}
