.sign-up-form-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
  padding-bottom: 50px;
  background-color: #3e3e42;
}

.sign-up-inner-div {
  align-items: center;
  padding: 30px;
  background: rgb(244, 47, 47);
  background: linear-gradient(
    124deg,
    rgba(244, 47, 47, 0.8071603641456583) 3%,
    rgba(240, 128, 128, 1) 6%,
    rgba(241, 100, 100, 1) 34%,
    rgba(240, 128, 128, 1) 47%,
    rgba(242, 96, 96, 1) 62%,
    rgba(240, 128, 128, 1) 73%,
    rgba(239, 108, 108, 1) 95%,
    rgba(235, 11, 11, 1) 98%
  );
  border-style: outset;
  border-width: 3px;
  border-color: grey;
}

.h1-signup {
  font-size: 50px;
  text-align: center;
}

.div-firstname {
  padding: 20px 0 20px 0;
}

.firstname-txt {
  font-size: 30px;
  text-align: center;
}

.div-lastname {
  padding: 20px 0 20px 0;
}

.lastname-txt {
  font-size: 30px;
  text-align: center;
}

.div-displayname {
  padding: 20px 0 20px 0;
}

.displayname-txt {
  font-size: 30px;
  text-align: center;
}

.div-email {
  padding: 20px 0 20px 0;
}

.email-txt {
  font-size: 30px;
  text-align: center;
}

.div-password {
  padding: 20px 0 20px 0;
}

.password-txt {
  font-size: 30px;
  text-align: center;
}

.input-box-sign-up {
  width: 90%;
  padding: 10px;
}

.div-button {
  padding: 20px;
  text-align: center;
  margin: auto;
}

.button-txt {
  font-size: 30px;
  background-color: white;
  color: black;
  padding: 10px;
}

.errors {
  color: white;
  font-size: 30px;
}

.toast-signup-containter {
  text-align: center;
  height: 150px;
  width: 450px;
  color: white;
  background-color: green;
  font-size: 35px;
}

@media screen and (max-width: 800px) {
  .form-main {
    margin-top: 5%;
  }

  .inner-div-signup {
    align-items: center;
    padding: 30px;
    background-color: lightcoral;
  }

  .h1-signup {
    font-size: 30px;
    text-align: center;
  }

  .div-firstname {
    padding: 10px 0 10px 0;
  }

  .firstname-txt {
    font-size: 20px;
    text-align: center;
  }

  .div-lastname {
    padding: 10px 0 10px 0;
  }

  .lastname-txt {
    font-size: 20px;
    text-align: center;
  }

  .div-displayname {
    padding: 10px 0 10px 0;
  }

  .displayname-txt {
    font-size: 20px;
    text-align: center;
  }

  .div-email {
    padding: 10px 0 10px 0;
  }

  .email-txt {
    font-size: 20px;
    text-align: center;
  }

  .div-password {
    padding: 10px 0 10px 0;
  }

  .password-txt {
    font-size: 20px;
    text-align: center;
  }

  .input-box-sign-up {
    width: 90%;
    padding: 5px;
  }

  .div-button {
    padding: 10px;
    text-align: center;
    margin: auto;
  }

  .button-txt {
    font-size: 20px;
    background-color: white;
    color: black;
    padding: 10px;
  }

  .errors {
    color: white;
    font-size: 30px;
  }

  .toast-signup-containter {
    text-align: center;
    height: 100px;
    width: 100px;
    color: white;
    background-color: green;
    font-size: 20px;
  }
}
