.total-landingpage {
  background-color: #3e3e42;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-direction: wrap;
  height: max-content;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
}

.landing-page-main-div {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.overview-button,
.pilot-info-button,
.google-map-button,
.comments-button {
  display: flex;
  padding: 20px;
  height: 200px;
  width: 400px;
  font-size: 40px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: 2px outset white;
  margin-bottom: 50px;
}

.overview-button {
  background-color: black;
  color: white;
}

.pilot-info-button {
  background-color: orange;
  color: white;
}

.google-map-button {
  background-color: blue;
  color: white;
}

.comments-button {
  padding: 20px;
  background-color: rebeccapurple;
  color: white;
  text-align: center;
}

@media screen and (max-width: 850px) {
  .total-landingpage {
    background-color: #3e3e42;
    display: flex;
    height: 800px;
  }

  .landing-page-main-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    
  }

  .overview-button,
  .pilot-info-button,
  .google-map-button,
  .comments-button {
    padding: 5px;
    height: 100px;
    width: 200px;
    font-size: 20px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

  .overview-button {
    background-color: black;
    color: white;
  }

  .pilot-info-button {
    background-color: orange;
    color: white;
  }

  .google-map-button {
    background-color: blue;
    color: white;
    text-align: center;
  }

  .comments-button {
    padding: 10px;
    background-color: rebeccapurple;
    color: white;
  }
}
