.inner-card-div {
  display: flex;
  height: 280px;
  width: 280px;
  margin: 2px 1.5px 2px 0;
  font-size: 40px;
  color: white;
  /* text-shadow: 2px 2px 0px #ffffff, 5px 4px 0px rgba(0, 0, 0, 0.15); */
  border-radius: 26px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#controlled {
  border: 15px ridge red;
}

#uncontrolled {
  border: 15px ridge green;
}

@media screen and (max-width: 800px) {
  .inner-card-div {
    display: flex;
    height: 100px;
    width: 100px;
    margin: 2px 1.5px 2px 0;
    font-size: 10px;
    color: white;
    /* text-shadow: 2px 2px 0px #ffffff, 5px 4px 0px rgba(0, 0, 0, 0.15); */
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  #controlled {
    border: 5px ridge red;
  }

  #uncontrolled {
    border: 5px ridge green;
  }
}
