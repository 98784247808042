.footer-div {
  background: rgb(244, 47, 47);
  background: linear-gradient(
    124deg,
    rgba(244, 47, 47, 0.8071603641456583) 3%,
    rgba(240, 128, 128, 1) 6%,
    rgba(241, 100, 100, 1) 34%,
    rgba(240, 128, 128, 1) 47%,
    rgba(242, 96, 96, 1) 62%,
    rgba(240, 128, 128, 1) 73%,
    rgba(239, 108, 108, 1) 95%,
    rgba(235, 11, 11, 1) 98%
  );
  border-style: outset;
  border-width: 3px;
  border-color: grey;
  width: 99%;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  text-align: center;
}

.footer-h1 {
  font-size: 24px;
}

@media screen and (max-width: 850px) {
  .footer-div {
    background: rgb(244, 47, 47);
    background: linear-gradient(
      124deg,
      rgba(244, 47, 47, 0.8071603641456583) 3%,
      rgba(240, 128, 128, 1) 6%,
      rgba(241, 100, 100, 1) 34%,
      rgba(240, 128, 128, 1) 47%,
      rgba(242, 96, 96, 1) 62%,
      rgba(240, 128, 128, 1) 73%,
      rgba(239, 108, 108, 1) 95%,
      rgba(235, 11, 11, 1) 98%
    );
    padding: 10px;
    font-size: 10px;
    border-style: outset;
    border-width: 3px;
    border-color: grey;
    width: 80%;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    text-align: center;
  }
  .footer-h1 {
    font-size: 2em;
  }
}
