.header-container {
  display: flex;
  justify-content: space-evenly;
  font-size: 50px;
  background: rgb(244, 47, 47);
  background: linear-gradient(
    124deg,
    rgba(244, 47, 47, 0.8071603641456583) 3%,
    rgba(240, 128, 128, 1) 6%,
    rgba(241, 100, 100, 1) 34%,
    rgba(240, 128, 128, 1) 47%,
    rgba(242, 96, 96, 1) 62%,
    rgba(240, 128, 128, 1) 73%,
    rgba(239, 108, 108, 1) 95%,
    rgba(235, 11, 11, 1) 98%
  );
  border-style: outset;
  border-width: 3px;
  border-color: grey;
  width: 99%;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.header-home,
.header-sign-in,
.header-sign-up,
.header-sign-out {
  color: lightyellow;
  text-decoration: none;
}

.header-home:hover,
.header-sign-in:hover,
.header-sign-up:hover,
.header-sign-out:hover {
  color: black;
  cursor: pointer;
}

@media screen and (max-width: 850px) {
  .header-container {
    display: flex;
    justify-content: space-evenly;
    font-size: 1em;
    background: rgb(244, 47, 47);
    background: linear-gradient(
      124deg,
      rgba(244, 47, 47, 0.8071603641456583) 3%,
      rgba(240, 128, 128, 1) 6%,
      rgba(241, 100, 100, 1) 34%,
      rgba(240, 128, 128, 1) 47%,
      rgba(242, 96, 96, 1) 62%,
      rgba(240, 128, 128, 1) 73%,
      rgba(239, 108, 108, 1) 95%,
      rgba(235, 11, 11, 1) 98%
    );
    border-style: outset;
    border-width: 3px;
    border-color: grey;
    width: 99%;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }
}
