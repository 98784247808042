.ptnotadmin-total-div {
  background-color: #3e3e42;
  padding-top: 5%;
  padding-bottom: 100%;
}

.ptnotadmin-main-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: max-content;
  background-color: lightcoral;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  padding: 5px;
}

.ptnotfood,
.ptnothotel,
.ptnotuber,
.ptnotlift,
.ptnottaxi,
.ptnotc1name,
.ptnotc1phone,
.ptnotc2name,
.ptnotc2phone,
.ptnotrentalcars,
.ptnotrentallocation,
.ptnoticing,
.ptnotfueling,
.ptnotfuelingtypes,
.ptnotmx,
.ptnothanger,
.ptnothangersize,
.ptnotclearance,
.ptnotcustoms,
.ptnottargetadd,
.ptnottargetphone,
.ptnotwalmartadd,
.ptnotwalmartphone,
.ptnothuffer,
.ptnotother {
  width: 99%;
  text-align: center;
  border: 2px solid white;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-bottom: 10px;
}

.ptnoth1 {
  margin-top: 5px;
  font-size: 40px;
  color: black;
}

.pptnotanswer {
  font-size: 40px;
  color: white;
}

@media screen and (max-width: 850px) {
  .ptnoth1 {
    margin-top: 5px;
    font-size: 20px;
    color: black;
  }
  .pptnotanswer {
    font-size: 20px;
    color: white;
  }
}
