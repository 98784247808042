.total-div {
  background-color: #3e3e42;
  padding-bottom: 20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.div-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 50px;
  margin-left: auto;
  margin-right: auto;
}

.link {
  color: yellow;
  text-decoration: none;
}

.discord-link {
  color: yellow;
  text-decoration: none;
}

.hotel,
.restaurants,
.airports,
.accept {
  border-left: outset 10px black;
  border-right: outset 5px grey;
  border-top: outset 5px grey;
  border-bottom: solid 5px grey;
  border-top-right-radius: 5%;
  border-bottom-right-radius: 5%;
  justify-items: center;
  text-align: center;
  padding: 20px;
  margin-right: 10px;
  margin-top: 10px;
  color: lightyellow;
  transition: transform 0.2s;
}

.hotel:hover,
.restaurants:hover {
  transform: scale(1.1);
  margin-bottom: 10px;
}

.accept:hover,
.airports:hover {
  transform: scale(1.1);
  margin-top: 20px;
}

.hotel {
  background: rgb(42, 49, 251);
  background: linear-gradient(
    299deg,
    rgba(42, 49, 251, 1) 17%,
    rgba(232, 230, 10, 0.3477766106442577) 25%,
    rgba(42, 49, 251, 1) 42%,
    rgba(42, 49, 251, 1) 60%,
    rgba(232, 230, 10, 0.25253851540616246) 86%,
    rgba(42, 49, 251, 1) 91%
  );
}

.restaurants {
  background: rgb(242, 78, 78);
  background: linear-gradient(
    299deg,
    rgba(242, 78, 78, 1) 17%,
    rgba(232, 230, 10, 0.3477766106442577) 25%,
    rgba(242, 78, 78, 1) 42%,
    rgba(244, 47, 47, 1) 60%,
    rgba(232, 230, 10, 0.25253851540616246) 86%,
    rgba(242, 81, 81, 1) 91%
  );
}

.airports {
  background: rgb(251, 161, 42);
  background: linear-gradient(
    299deg,
    rgba(251, 161, 42, 1) 17%,
    rgba(232, 230, 10, 0.3477766106442577) 25%,
    rgba(251, 161, 42, 1) 42%,
    rgba(251, 161, 42, 1) 60%,
    rgba(232, 230, 10, 0.25253851540616246) 86%,
    rgba(251, 161, 42, 1) 91%
  );
}

.accept {
  background: rgb(232, 230, 10);
  background: linear-gradient(
    299deg,
    rgba(232, 230, 10, 0.3477766106442577) 9%,
    rgba(224, 8, 242, 1) 18%,
    rgba(224, 8, 242, 1) 42%,
    rgba(224, 8, 242, 1) 60%,
    rgba(224, 8, 242, 1) 89%,
    rgba(232, 230, 10, 0.25253851540616246) 94%
  );
}
.discord-channel {
  background: rgb(244, 47, 47);
  background: linear-gradient(
    124deg,
    rgba(244, 47, 47, 0.8071603641456583) 3%,
    rgba(240, 128, 128, 1) 6%,
    rgba(241, 100, 100, 1) 34%,
    rgba(240, 128, 128, 1) 47%,
    rgba(242, 96, 96, 1) 62%,
    rgba(240, 128, 128, 1) 73%,
    rgba(239, 108, 108, 1) 95%,
    rgba(235, 11, 11, 1) 98%
  );
  border-style: outset;
  border-width: 3px;
  border-color: grey;
  width: 99%;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  text-align: center;
  margin-top: 40px;
}

#h1-tag {
  text-align: center;
}

#h1-tag:hover {
  cursor: pointer;
  color: black;
}

@media screen and (max-width: 800px) {
  .img-pic {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    height: 100px;
  }
  .div-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #h1-tag {
    text-align: center;
    font-size: 1em;
  }
  .discord-channel {
    margin-right: auto;
    margin-left: auto;
    width: 87%;
    margin-top: 10px;
    margin-bottom: 50px;
  }
}
