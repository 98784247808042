.total-youhavetosignin {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  padding-bottom: 100%;
  background-color: #3e3e42;
}

.parent-div {
  display: flex;
  height: 500px;
  width: auto;
  align-items: center;
  justify-content: center;
}

.child-div {
  padding: 20px;
  background: rgb(244, 47, 47);
  background: linear-gradient(
    124deg,
    rgba(244, 47, 47, 0.8071603641456583) 3%,
    rgba(240, 128, 128, 1) 6%,
    rgba(241, 100, 100, 1) 34%,
    rgba(240, 128, 128, 1) 47%,
    rgba(242, 96, 96, 1) 62%,
    rgba(240, 128, 128, 1) 73%,
    rgba(239, 108, 108, 1) 95%,
    rgba(235, 11, 11, 1) 98%
  );
  border-style: outset;
  border-width: 3px;
  border-color: grey;
}

.child-h1 {
  font-size: 50px;
  color: white;
  text-align: center;
}
