.state-main-div {
  background-color: #3e3e42;
  height: 100%;
  padding-bottom: 100%;
}

.code-header-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  color: lightyellow;
}
.card-div {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 100%;
  padding: 10px;
  align-items: center;
}
.input-box {
  font-size: 25px;
  padding: 15px;
  width: 50%;
}

.state-link {
  text-decoration: none;
}

@media screen and (max-width: 800px) {
  .code-header-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: lightyellow;
  }

  .input-box {
    font-size: 20px;
    padding: 10px;
    width: 50%;
  }
}
