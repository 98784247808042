.pilot-main-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: lightsalmon;
}

.food {
  background-color: lightcoral;
  font-size: 25px;
  text-align: center;
  width: 80%;
}

.hotels {
  background-color: plum;
  font-size: 25px;
  text-align: center;
  width: 80%;
}

.ride {
  background-color: lightcoral;
  font-size: 25px;
  text-align: center;
  width: 80%;
}

.taxi-input {
  padding: 10px;
  font-size: 20px;
  width: 50%;
}

.clean {
  background-color: plum;
  font-size: 25px;
  text-align: center;
  width: 80%;
}

.input-cleaner {
  padding: 10px;
  font-size: 20px;
  width: 90%;
}

.rent {
  background-color: lightcoral;
  font-size: 25px;
  text-align: center;
  width: 80%;
}

.ice {
    background-color: plum;
    font-size: 25px;
    text-align: center;
    width: 80%;
}

.fuel {
    background-color: lightcoral;
    font-size: 25px;
    text-align: center;
    width: 80%;
}

.mx {
    background-color: plum;
    font-size: 25px;
    text-align: center;
    width: 80%;
}

.hanger {
    background-color: lightcoral;
    font-size: 25px;
    text-align: center;
    width: 80%;
}

.clearance {
    background-color: plum;
    font-size: 25px;
    text-align: center;
    width: 80%;
}

.customs {
    background-color: lightcoral;
    font-size: 25px;
    text-align: center;
    width: 80%;
}

.target {
    background-color: plum;
    font-size: 25px;
    text-align: center;
    width: 80%;
}

.input-target{
    padding: 10px;
    font-size: 20px;
}

.walmart {
    background-color: lightcoral;
    font-size: 25px;
    text-align: center;
    width: 80%;
}

.huffer {
    background-color: plum;
    font-size: 25px;
    text-align: center;
    width: 80%;
}

.otherInfo {
  width: 500px;
  height: 400px;
}

.button {
  font-size: 32px;
  background-color: yellow;
  color: black;
}
