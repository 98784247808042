.parent-state-div {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  padding-bottom: 100%;
  background-color: #3e3e42;
}

.search-state-text {
  font-size: 40px;
}

.input-box-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 200px;
  color: lightyellow;
  margin-top: 0;
  margin-bottom: 10px;
}

.type-state-div {
  width: 80%;
  align-items: center;
}

.input-box-states {
  font-size: 25px;
  padding: 15px;
  width: 100%;
}

.link-states {
  cursor: pointer;
  text-decoration: none;
}

@media screen and (max-width: 800px) {
  .search-state-text {
    font-size: 20px;
    text-align: center;
  }
  .input-box-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    color: lightyellow;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .input-box-states {
    font-size: 10px;
    padding: 15px;
    width: 80%;
  }
}
