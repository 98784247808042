.profile-main-form {
  background: rgb(244, 47, 47);
  background: linear-gradient(
    124deg,
    rgba(244, 47, 47, 0.8071603641456583) 3%,
    rgba(240, 128, 128, 1) 6%,
    rgba(241, 100, 100, 1) 34%,
    rgba(240, 128, 128, 1) 47%,
    rgba(242, 96, 96, 1) 62%,
    rgba(240, 128, 128, 1) 73%,
    rgba(239, 108, 108, 1) 95%,
    rgba(235, 11, 11, 1) 98%
  );
  border-style: outset;
  border-width: 3px;
  border-color: grey;
  width: 80%;
  height: max-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.profile-inner-div-left {
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: center;
}

.profile-h1-text {
  font-size: 50px;
}

.profile-text-displayname {
  color: lightyellow;
  font-size: 30px;
}

.profile-h2-text {
  font-size: 16px;
}

.profile-firstname,
.profile-lastname,
.profile-displayname {
  font-size: 30px;
}

.input-box-profile {
  padding: 10px;
  font-size: 25px;
  width: 50%;
}

.profile-inner-div-right {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 50%;
  text-align: center;
}

.profile-choose-file {
  padding: 10px;
  font-size: 25px;
  margin-left: 15%;
}

@media screen and (max-width: 850px) {
  .profile-main-form {
    width: 90%;
    height: max-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .profile-inner-div-left {
    width: 100%;
    text-align: center;
  }

  .profile-inner-div-right {
    justify-items: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }
  .profile-choose-file {
    padding: 10px;
    font-size: 16px;
  }
}
