.hotel-total-div {
  background-color: #3e3e42;
  padding-top: 5%;
  padding-bottom: 100%;
}

.form-hotelrv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: max-content;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
  margin-top: 5%;
  background-color: lightcoral;
}

.inner-div-hotelrv {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  padding: 10px;
  width: 100%;
}

.hotel-h1-text {
  text-align: center;
  font-size: 40px;
}

#h1-review-airport {
  color: white;
}

.hotel-h2-text {
  text-align: center;
  font-size: 20px;
}

.hotel-name,
.hotel-address,
.hotel-phone,
.hotel-quality,
.hotel-breakfast,
.hotel-gym,
.hotel-rest,
.hotel-shuttle,
.hotel-laundry,
.hotel-noise,
.hotel-saftey,
.hotel-other {
  width: 95%;
}

.hotel-text,
.address-text,
.phone-text,
.quality-text,
.breakfast-text,
.gym-text,
.rest-text,
.shuttle-text,
.laundry-text,
.noise-text,
.saftey-text,
.other-text {
  text-align: center;
  font-size: 30px;
}

.input-box-nameaddphone {
  width: 100%;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.input-box-hotelrv {
  height: 300px;
  width: 100%;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.review-button {
  padding: 10px;
  margin-top: 50px;
  font-size: 25px;
}

@media screen and (max-width: 850px) {
  .form-hotelrv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: max-content;
    margin-right: auto;
    margin-left: auto;
    padding: 20px;
    margin-top: 5%;
    background-color: lightcoral;
  }
}
