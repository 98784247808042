.form-main-sign-in {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
  padding-bottom: 50px;
  background-color: #3e3e42;
}

.inner-div-signup {
  align-items: center;
  padding: 30px;
  background: rgb(244, 47, 47);
  background: linear-gradient(
    124deg,
    rgba(244, 47, 47, 0.8071603641456583) 3%,
    rgba(240, 128, 128, 1) 6%,
    rgba(241, 100, 100, 1) 34%,
    rgba(240, 128, 128, 1) 47%,
    rgba(242, 96, 96, 1) 62%,
    rgba(240, 128, 128, 1) 73%,
    rgba(239, 108, 108, 1) 95%,
    rgba(235, 11, 11, 1) 98%
  );
  border-style: outset;
  border-width: 3px;
  border-color: grey;
}

.sign-h1-text {
  font-size: 50px;
  text-align: center;
}

.email-div {
  padding: 20px 0 20px 0;
  overflow: hidden;
}

.email-text {
  font-size: 50px;
  text-align: center;
}

.pass-div {
  padding: 20px 0 20px 0;
  overflow: hidden;
}

.pass-txt {
  text-align: center;
  font-size: 50px;
}

.input-box-sign-in {
  padding: 10px;
  width: 100%;
}

.form-button {
  padding: 20px;
  text-align: center;
  margin: auto;
}

.button-button {
  font-size: 30px;
  background-color: white;
  color: black;
  padding: 10px;
}

.errors-txt {
  color: white;
  font-size: 30px;
}

.sign-in-link {
  text-decoration: none;
}

.forgot-pass {
  color: lightyellow;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 850px) {
  .form-main-sign-in {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
    padding-bottom: 50px;
    background-color: #3e3e42;
  }

  .inner-div-signup {
    align-items: center;
    background-color: lightcoral;
    padding: 20px;
  }

  .sign-h1-text {
    font-size: 30px;
    text-align: center;
  }

  .email-div {
    padding: 10px 0 10px 0;
    overflow: hidden;
  }

  .email-text {
    font-size: 20px;
    text-align: center;
  }

  .pass-div {
    padding: 10px 0 10px 0;
    overflow: hidden;
  }

  .pass-txt {
    text-align: center;
    font-size: 20px;
  }

  .input-box-sign-in {
    padding: 5px;
    width: 100%;
  }

  .form-button {
    padding: 10px;
    text-align: center;
    margin: auto;
  }

  .button-button {
    font-size: 20px;
    background-color: white;
    color: black;
    padding: 5px;
  }

  .errors-txt {
    color: white;
    font-size: 20px;
  }

  .forgot-pass {
    text-align: center;
    font-size: 20px;
  }
}
