.airport-total-div {
  background-color: #3e3e42;
  padding-top: 5%;
  padding-bottom: 100%;
}

.airport-overview-main-div {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  flex-wrap: wrap;
  width: 80%;
  height: 90%;
  background-color: lightcoral;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border: 5px outset white;
}

.name-div,
.lat-long-div,
.address-div,
.extra-div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  justify-content: space-evenly;
  align-items: center;
  font-size: 3em;
  height: auto;
  border: white solid 1px;
}

.indiv-div-icao {
  color: lightyellow;
}

.indiv-div-over {
  padding: 10px;
}

@media screen and (max-width: 850px) {
  .airport-overview-main-div {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    flex-wrap: wrap;
    width: 80%;
    height: max-content;
    margin-top: 10px;
    background-color: lightcoral;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
  }

  .name-div,
  .lat-long-div,
  .address-div,
  .extra-div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    justify-content: space-evenly;
    font-size: 2em;
    height: auto;
    border: white solid 1px;
    padding: 10px;
  }

  .indiv-div-over {
    padding: 10px;
  }
}
