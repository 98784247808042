.toast-signin-containter,
.toast-signup-containter {
  text-align: center;
  height: 150px;
  width: 450px;
  color: white;
  background-color: green;
  font-size: 35px;
}

.toast-signout-containter {
  text-align: center;
  height: 150px;
  width: 450px;
  color: white;
  background-color: black;
  font-size: 35px;
}

.toast-signin-error-containter,
.toast-signout-error-containter,
.toast-signup-error-containter {
  text-align: center;
  height: 150px;
  width: 450px;
  color: white;
  background-color: red;
  font-size: 35px;
}
