.existing-review-main-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: lightcoral;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: max-content;
  padding: 20px;
  margin-top: 5%;
  border: 2px solid white;
}

.link-reviews {
  color: white;
}

.existing-h1 {
  background-color: lightcoral;
  border: 2px solid white;
  font-size: 50px;
  width: 99%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

.review-indi-main-div {
  background-color: lightgrey;
  width: auto;
  height: fit-content;
  margin-top: 20px;
  margin-right: 10px;
  border: 2px outset white;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.review-name {
  text-align: center;
  font-size: 40px;
  padding-top: 5px;
}

.review-add {
  text-align: center;
  font-size: 30px;
  padding-top: 5px;
}

.review-phone {
  text-align: center;
  font-size: 20px;
  color: blue;
  padding-top: 5px;
}

.review-add-review {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  width: 99%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  padding-bottom: 10px;
  background-color: lightcoral;
}

.review-another-h1 {
  font-size: 30px;
}

.review-add-button {
  font-size: 20px;
  padding: 10px;
  background-color: white;
  color: black;
}

.review-edit {
  color: blue;
  font-size: 20px;
}

.review-edit:hover {
  cursor: pointer;
}

@media screen and (max-width: 850px) {
  .existing-review-main-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: lightcoral;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    padding: 10px;
    margin-top: 5%;
    border: 2px solid white;
  }

  .existing-h1 {
    background-color: lightcoral;
    border: 2px solid white;
    font-size: 25px;
    width: 95%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .review-indi-main-div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: lightgrey;
    width: 95%;
    height: 200px;
    margin-top: 20px;
    border: 2px solid white;
    align-items: center;
  }

  .review-name {
    text-align: center;
    font-size: 1.5em;
    padding-top: 5px;
  }

  .review-add {
    text-align: center;
    font-size: 1.5em;
    padding-top: 5px;
  }

  .review-phone {
    text-align: center;
    font-size: 1em;
    color: blue;
    padding-top: 5px;
  }

  .review-add-review {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    width: 95%;
    margin-top: 20px;
    padding-bottom: 10px;
  }

  .review-another-h1 {
    font-size: 1.5em;
    text-align: center;
  }

  .review-add-button {
    font-size: 1em;
    padding: 5px;
    background-color: white;
    color: black;
  }

  .review-edit {
    color: blue;
    font-size: 1em;
  }

  .review-edit:hover {
    cursor: pointer;
  }
}
